<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refReportListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="channel_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getSlaData"
      >

        <!-- Column: User -->
        <template #cell(action)="data">
          <p class="font-weight-bold mb-50">
            <b-button
              variant="outline-warning"
              size="sm"
              @click="editData(data.item)"
            >
              Edit
            </b-button>
          </p>
        </template>
      </b-table>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-edit-sla"
      ref="modal-edit-sla"
      title="Modify SLA"
      ok-title="Save"
      @ok.prevent="saveSla"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="m-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul class="pl-2">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  <template v-for="txt in item">
                    {{ txt }}
                  </template>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
          >
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Urgent"
              rules="required"
            >
              <b-form-group
                label="Urgent"
                label-for="urgent"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="urgent"
                    v-model="dataSla.urgent"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-input-group-append is-text>
                    Hours
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="High"
              rules="required"
            >
              <b-form-group
                label="High"
                label-for="high"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="high"
                    v-model="dataSla.high"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-input-group-append is-text>
                    Hours
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Medium"
              rules="required"
            >
              <b-form-group
                label="Medium"
                label-for="medium"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="medium"
                    v-model="dataSla.medium"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-input-group-append is-text>
                    Hours
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Low"
              rules="required"
            >
              <b-form-group
                label="Low"
                label-for="low"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="low"
                    v-model="dataSla.low"
                    trim
                  />

                  <b-input-group-append is-text>
                    Hours
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, VBModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BCardText,
  BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Notification

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const toast = useToast()

    // Table Handlers
    const tableColumns = [
      { key: 'name' },
      { key: 'urgent', label: 'Urgent (Hours)' },
      { key: 'high', label: 'High (Hours)' },
      { key: 'medium', label: 'Medium (Hours)' },
      { key: 'low', label: 'Low (Hours)' },
      { key: 'action' },
    ]

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const sortBy = ref('ticket_id')
    const isSortDirDesc = ref(true)

    return {
      toast,

      refFormObserver,
      getValidationState,

      tableColumns,
      sortBy,
      isSortDirDesc,
    }
  },
  data() {
    return {
      required,
      dataList: [],
      dataSla: {},
      reference: {},
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  created() {
    this.getReference()
    this.getSlaData()
  },
  methods: {
    editData(item) {
      this.dataSla = item
      this.$refs['modal-edit-sla'].show()
    },
    saveSla() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = this.dataSla

      this.$http.post('ref/sla/update', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.$refs['modal-edit-sla'].hide()
            this.getSlaData()

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'New SLA Saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Update SLA',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getReference() {
      this.$http.post('ref/tx', true, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.reference = resp.data

            const liburAll = this.reference.holidays
            const libur = []

            Object.keys(liburAll).map(data => {
              if (data !== 'created-at') {
                const tgl = moment(data).format('YYYY-MM-DD')
                libur.push(tgl)
              }
              return data
            })
            this.holidays = libur
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Refences',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getSlaData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post('ref/sla', true, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.dataList = resp.data.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching SLA list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
